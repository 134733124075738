import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { Button, SvgIcon } from '@material-ui/core';
import { UserProfile } from 'components';
import * as RoleManager from 'services/role';

import {
  Dashboard,
  Description,
  LocalLibrary,
  Pageview,
} from '@material-ui/icons';

import { ReactComponent as LabManagementIcon } from './assets/lab-management.svg';
import { ReactComponent as ProjectManagementIcon } from './assets/project-management.svg';

import { LeftMenu } from '../LeftMenu/LeftMenu';
import RecentNotifications from '../RecentNotification/RecentNotifications';

import './Header.scss';
import { useTranslation } from 'react-i18next';

type HeaderProps = {
  toggleSidebar: Function;
};

export const Header: React.FC<HeaderProps> = () => {
  const activeRoute = window.location.pathname;

  const { t } = useTranslation(['header', 'common']);

  const path = activeRoute.split('/')[2] || 'dashboard';

  useEffect(() => {
    document.title = `LabV - ${t('tabTitle_' + path, { ns: 'common' })}`;
  }, [path, t]);

  return (
    <AppBar
      className={`firestarter-header ${'firestarter-header--closed'}`}
      color="default"
      position="fixed"
    >
      <Toolbar>
        <div className="brand">
          <img
            src={'/images/logo-temp.png'}
            alt="LabV Logo"
            className="brand-logo"
          />
        </div>
        {
          <Button
            component={Link}
            to="/dashboard"
            className={`btn-menu-left${
              activeRoute === '/dashboard' ? ' active' : ''
            }`}
            startIcon={<Dashboard />}
          >
            <span className="menu-left-wrap">
              <div className="menu-left-name">{t('dashboard')}</div>
            </span>
          </Button>
        }
        {(RoleManager.isAbleTo('company', 'findAll') ||
          RoleManager.isAbleTo('company', 'findOne')) && (
          <Button
            component={Link}
            to="/dashboard/company"
            className={`btn-menu-left${
              activeRoute.includes('/company') ? ' active' : ''
            }`}
            startIcon={<LocalLibrary />}
          >
            <span className="menu-left-wrap">
              <div className="menu-left-name">{t('customerManagement')}</div>
            </span>
          </Button>
        )}
        {(RoleManager.isAbleTo('project', 'findAll') ||
          RoleManager.isAbleTo('project', 'findOne')) && (
          <Button
            component={Link}
            to="/dashboard/project"
            className={`btn-menu-left${
              activeRoute.includes('/project') ? ' active' : ''
            }`}
            startIcon={
              <SvgIcon fontSize="large">
                <ProjectManagementIcon />
              </SvgIcon>
            }
          >
            <span className="menu-left-wrap">
              <div className="menu-left-name">{t('projectManagement')}</div>
            </span>
          </Button>
        )}
        {(RoleManager.isAbleTo('project', 'findAll') ||
          RoleManager.isAbleTo('project', 'findOne')) && (
          <Button
            component={Link}
            to="/dashboard/data-search"
            className={`btn-menu-left${
              activeRoute.includes('/data-search') ? ' active' : ''
            }`}
            startIcon={<Pageview />}
          >
            <span className="menu-left-wrap">
              <div className="menu-left-name">{t('dataSearch')}</div>
            </span>
          </Button>
        )}
        {(RoleManager.isAbleTo('material', 'findAll') ||
          RoleManager.isAbleTo('material', 'findOne')) && (
          <Button
            component={Link}
            to="/dashboard/material"
            className={`btn-menu-left${
              activeRoute.includes('/material') ? ' active' : ''
            }`}
            startIcon={<Description />}
          >
            <span className="menu-left-wrap">
              <div className="menu-left-name">{t('resources', {ns:'materials'})}</div>
            </span>
          </Button>
        )}

        <LeftMenu
          name={t('labManagement')}
          activeRoute={activeRoute}
          icon={
            <SvgIcon fontSize="large">
              <LabManagementIcon />
            </SvgIcon>
          }
          items={[
            {
              name: t('locations'),
              url: 'location',
              hasAccess:
                RoleManager.isAbleTo('location', 'findAll') ||
                RoleManager.isAbleTo('location', 'findOne'),
            },
            {
              name: t('devices'),
              url: 'device',
              hasAccess:
                (RoleManager.isAbleTo('device', 'findAll') ||
                  RoleManager.isAbleTo('device', 'findOne')) &&
                RoleManager.isAbleTo('device', 'updateOne'),
            },
            {
              name: t('groups'),
              url: 'group',
              hasAccess:
                (RoleManager.isAbleTo('group', 'findAll') ||
                  RoleManager.isAbleTo('group', 'findOne')) &&
                RoleManager.isAbleTo('group', 'editMembers'),
            },
            {
              name: t('users'),
              url: 'user',
              hasAccess:
                (RoleManager.isAbleTo('user', 'findAll') ||
                  RoleManager.isAbleTo('user', 'findOne')) &&
                (RoleManager.isAbleTo('user', 'create') ||
                  RoleManager.isAbleTo('user', 'updateOne') ||
                  RoleManager.isAbleTo('user', 'deleteOne')),
            },
            {
              name: t('templates'),
              url: 'template',
              hasAccess: RoleManager.isAbleTo('project', 'create'),
            },
            {
              name: t('projectPlans'),
              url: 'overview-project-plans',
              hasAccess: RoleManager.isAbleTo('project', 'create'),
            },
            {
              name: t('testStandardHeader'),
              url: 'test-standard',
              hasAccess:
                RoleManager.isAbleTo('project', 'findAll') ||
                RoleManager.isAbleTo('project', 'findOne'),
            },
          ]}
        />
        <div className="grow"></div>
        <React.Suspense fallback="loading..">
          <UserProfile />
          <RecentNotifications />
        </React.Suspense>
      </Toolbar>
    </AppBar>
  );
};
