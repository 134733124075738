import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SampleView } from './SampleView';
import { fetchSamples } from './SampleActions';
import { selectSampleToEdit } from '../SampleForm/SampleFormActions';

export default (props) => {
    const { t } = useTranslation(['materials', 'common']);

    const {
        filters,
        setFilters,
        isInModal,
        onSelect,
        selectedData,
        tabValue,
    } = props;

    const dispatch = useDispatch();

    const handleSampleEdit = async(item) => {
        await dispatch(selectSampleToEdit(item));
    };

    const handleView = (row) => {
        props.history.push(`/dashboard/sample/${row._id}`);
    };

    return (
        <SampleView
            filters={filters}
            setFilters={setFilters}
            isInModal={isInModal}
            onSelect={onSelect}
            selectedData={selectedData}
            handleView={handleView}
            handleEdit={handleSampleEdit}
            tabValue={tabValue}
            t={t}
        />
    );
};