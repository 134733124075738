import {
    ERROR,
    SUCCESS,
  } from 'containers/_Default/Notification/NotificationTypes';
import { GET_PROCESS_FORM, PROCESS_AUDIT, PROCESS_CLEAR, PROCESS_CREATE_SUCCESS, PROCESS_FORM_ADD_PARAMETERS, PROCESS_SINGLE_SUCCESS, PROCESS_UPDATE_SUCCESS } from './ProcessFormTypes';
import { createProcess, editProcess, getProcesses } from '../../repository/process';
import { getAudit } from '../../repository/audit';
import { PROCESS_SUCCESS } from '../Process/ProcessTypes';

export const newProcess = (data: any, callback: any) => {
    return async (dispatch) => {
        try {
        dispatch({
            type: GET_PROCESS_FORM,
        });
    
        const response = await createProcess(data);
        dispatch({
            type: PROCESS_CREATE_SUCCESS,
            payload: response.data,
        });
    
        if (typeof callback === 'function' && response?.data?._id) {
            callback(response.data);
        }
    
        dispatch({
            type: SUCCESS,
            payload: {
            isOpen: true,
            message: 'process_createSuccess',
            },
        });
    
        const audit = await getAudit(response.data._id);
        dispatch({
            type: PROCESS_AUDIT,
            payload: audit.data,
        });

        const processes = await getProcesses({ limit: 20, page: 0 });
            dispatch({
                type: PROCESS_SUCCESS,
                payload: processes.data,
            });
        } catch (error) {
        const message =
            error?.response?.data?.message ||
            error?.response?.data?.detail ||
            error?.response?.data?.title ||
            'error';
    
        dispatch({
            type: ERROR,
            payload: {
            isOpen: true,
            message,
            },
        });
        }
    };
};

export const addParameters = () => {
    return async (dispatch) => {
      try {
        dispatch({
          type: GET_PROCESS_FORM,
        });
  
        dispatch({
          type: PROCESS_FORM_ADD_PARAMETERS,
        });
      
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.response?.data?.detail ||
          error?.response?.data?.title ||
          'error';
  
        dispatch({
          type: ERROR,
          payload: {
            isOpen: true,
            message,
          },
        });
      }
    };
};

export const selectProcessToEdit = (data: any) => {
    return {
      type: PROCESS_SINGLE_SUCCESS,
      payload: data,
    };
};

export const updateProcess = (id: number, data: any) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: GET_PROCESS_FORM,
        });
  
        await editProcess(id, data);
        dispatch({
          type: PROCESS_UPDATE_SUCCESS,
        });
  
        dispatch({
          type: SUCCESS,
          payload: {
            isOpen: true,
            message: 'process_updateSuccess',
          },
        });
  
        const audit = await getAudit(id);
  
        dispatch({
          type: PROCESS_AUDIT,
          payload: audit.data,
        });
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.response?.data?.detail ||
          error?.response?.data?.title ||
          'error';
  
        dispatch({
          type: ERROR,
          payload: {
            isOpen: true,
            message,
          },
        });
      }
    };
  }; 

export const clearProcess = () => {
  return {
    type: PROCESS_CLEAR,
  };
};