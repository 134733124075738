import {
    ERROR,
    SUCCESS,
  } from 'containers/_Default/Notification/NotificationTypes';
import { getAudit } from '../../repository/audit';
import { GET_SAMPLE_FORM, SAMPLE_AUDIT, SAMPLE_CLEAR, SAMPLE_CREATE_SUCCESS, SAMPLE_SINGLE_SUCCESS, SAMPLE_UPDATE_SUCCESS } from './SampleFormTypes';
import { createSample, editSample, getSamples } from '../../repository/sample';
import { SAMPLE_SUCCESS } from '../Sample/SampleTypes';
import { ADD_SAMPLE_TO_EXPERIMENT, FLAG_PROCESS_USED_IN_SAMPLE } from '../ProjectRecipe/ProjectRecipeTypes';


export const selectSampleToEdit = (data: any) => {
    return {
      type: SAMPLE_SINGLE_SUCCESS,
      payload: data,
    };
};

export const newSample = (data: any, callback: any) => {
  return async (dispatch) => {
      try {
      dispatch({
          type: GET_SAMPLE_FORM,
      });
  
      const response = await createSample(data);
      dispatch({
          type: SAMPLE_CREATE_SUCCESS,
          payload: response.data,
      });
  
      if (typeof callback === 'function' && response?.data?._id) {
          callback(response.data);
      }
  
      dispatch({
          type: SUCCESS,
          payload: {
          isOpen: true,
          message: 'sample_createSuccess',
          },
      });
  
      const audit = await getAudit(response.data._id);
      dispatch({
          type: SAMPLE_AUDIT,
          payload: audit.data,
      });

      const samples = await getSamples({ limit: 20, page: 0 });
          dispatch({
              type: SAMPLE_SUCCESS,
              payload: samples.data,
          });
      } catch (error) {
      const message =
          error?.response?.data?.message ||
          error?.response?.data?.detail ||
          error?.response?.data?.title ||
          'error';
  
      dispatch({
          type: ERROR,
          payload: {
          isOpen: true,
          message,
          },
      });
      }
  };
};

export const updateSample = (id: number, data: any) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: GET_SAMPLE_FORM,
        });
  
        // await editSample(id, data);
        // dispatch({
        //   type: SAMPLE_UPDATE_SUCCESS,
        // });
  
        dispatch({
          type: SUCCESS,
          payload: {
            isOpen: true,
            message: 'sample_updateSuccess',
          },
        });
  
        const audit = await getAudit(id);
  
        dispatch({
          type: SAMPLE_AUDIT,
          payload: audit.data,
        });
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.response?.data?.detail ||
          error?.response?.data?.title ||
          'error';
  
        dispatch({
          type: ERROR,
          payload: {
            isOpen: true,
            message,
          },
        });
      }
    };
  }; 

export const clearSample = () => {
  return {
    type: SAMPLE_CLEAR,
  };
};

