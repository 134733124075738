import { SAMPLE_ERROR, SAMPLE_START, SAMPLE_SUCCESS } from "./SampleTypes";

  
  const INITIAL_STATE = {
    loading: false,
    error: false,
    data: [],
  };
  
  export default (state: any = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case SAMPLE_START:
        return { ...state, loading: true, error: null };
  
      case SAMPLE_SUCCESS:
        return { ...state, data: action.payload, loading: false };
  
      case SAMPLE_ERROR:
        return { ...state, loading: false, error: action.payload };
  
      default:
        return state;
    }
  };
  