import React, { useEffect, useMemo, useState } from 'react';
import { ITableHeaderColumn } from 'components/Table/components/TableHeader/TableHeader';
import { Table } from 'components/Table/Table';
import { RemoveRedEye } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import * as RoleManager from '../../services/role';
import { getProcesses } from '../../repository/process';
import { Box, Button, SvgIcon } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ProcessForm from '../ProcessForm/ProcessForm';



export const ProcessView = (props) => {
    const {
      filters,
      handleEdit,
      setFilters,
      tabValue,
      t,
    } = props;

    const config = JSON.parse(localStorage.getItem('config') || '{}');
    const [formOpen, setFormOpen] = useState(false as any);

    const formData = config?.forms?.processForm.tab1;

    const visibleFields: any = Object.values(formData?.fields)
    ?.map((field: any) => ({
      type: 'label',
      label: t(field.label),
      field: field.fieldName,
      sortable: field.sortable,
      showInOverviewTable: field.showInOverviewTable,
    }))
    .filter((field: any) => !!field.showInOverviewTable);

    const handleEditClick = (process) => {
        handleEdit(process);
        setFormOpen(true);
      };

    const tableColumns: ITableHeaderColumn[] = [
        ...visibleFields,
        {
          type: 'icons',
          icons: [
            {
              icon: <EditIcon />,
              show: RoleManager.isAbleTo('material', 'create'),
              callbackMethod: handleEditClick,
            },
          ],
        },
      ];

      const renderTabs = () => (
      <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
            {RoleManager.isAbleTo('material', 'create') ? (
              <Button
                onClick={() => setFormOpen(true)}
                color="primary"
                variant="contained"
              >
                <SvgIcon component={AddIcon} />
                {t('addNewProcess', { ns: 'materials' })}
              </Button>
            ) : null}
          </Box>
      <Table
      columnNames={tableColumns}
      fetchDataMethod={
        tabValue === 2 ? getProcesses : () => ({ data: { docs: [] } })
      }
      title={t('processes', { ns: 'materials' })}
      filtersPassed={filters}
      processData={(data) => {
        return data?.map((row) => {
          return {
            ...row,
            type: t('materialForm_recipe', {
              ns: 'materials',
            }),
          };
        });
      }}
    />
    </>
    );

  const renderMemoTabs = useMemo(() => renderTabs(), [tabValue, filters, t]);

  return (
    <div className="page-process">
      {renderMemoTabs}
      <ProcessForm
        open={formOpen}
        setOpen={setFormOpen}
        setFilters={setFilters}
        t={t}
        tabValue={tabValue}
        handleEdit={handleEdit}
      />
    </div>
  );
}