export const PROJECT_EXPERIMENTS_START = 'project-experiments/start';
export const PROJECT_EXPERIMENTS_SUCCESS = 'project-experiments/success';
export const PROJECT_EXPERIMENTS_ERROR = 'project-experiments/error';
export const PROJECT_EXPERIMENTS_RESET = 'project-experiments/reset';
export const EXPERIMENT_RECIPE_ADD_ROW = 'project-experiments/add-row';
export const EXPERIMENT_ADD = 'project-experiments/add';
export const EXPERIMENT_RECIPE_EDIT_PERCENTAGE =
  'project-experiments/edit-percentage';
export const EXPERIMENT_RECIPE_EDIT_MATERIAL =
  'project-experiments/edit-material';
export const EXPERIMENT_RECIPE_SAVE_SUCCESS =
  'project-experiments/save-success';
export const EXPERIMENT_MARK_FINAL = 'project-experiments/mark-final';
export const EXPERIMENT_COMPLETE = 'project-experiments/complete';
export const PROJECT_EXPERIMENT_FILTERED_EXPERIMENTS_SUCCESS =
  'project-project-experiments/save-filtered-experiments-success';
export const PROJECT_EXPERIMENT_FILTER_EXPERIMENT =
  'project-project-experiments/filter-experiment';
export const PROJECT_EXPERIMENTS_FILTERED_EXPERIMENTS_RESULTS_SUCCESS =
  'project-project-experiments/save-filtered-experiments-results-success';
export const ADD_PROCESS_TO_EXPERIMENT =
  'project-project-experiments/add-process-to-experiment';
export const EDIT_PROCESS_PARAMETER = 'project-recipe/edit-process-parameter';
export const ADD_SAMPLE_TO_EXPERIMENT = 'project-recipe/add-sample-to-experiment';
export const FLAG_PROCESS_USED_IN_SAMPLE = 'project-recipe/flag-process-used-in-sample';
