import React, { useEffect, useMemo, useState } from 'react';
import { ITableHeaderColumn } from 'components/Table/components/TableHeader/TableHeader';
import { Table } from 'components/Table/Table';
import { RemoveRedEye } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import * as RoleManager from '../../services/role';
import { Box } from '@material-ui/core';
import { getSamples } from '../../repository/sample';
import SampleForm from '../SampleForm/SampleForm';



export const SampleView = (props) => {
    const {
      filters,
      handleEdit,
      setFilters,
      tabValue,
      t,
    } = props;

    const config = JSON.parse(localStorage.getItem('config') || '{}');
    const [formOpen, setFormOpen] = useState(false as any);

    const formData = config?.forms?.sampleForm.tab1;

    const visibleFields: any = Object.values(formData?.fields)
    ?.map((field: any) => ({
      type: 'label',
      label: t(field.label),
      field: field.fieldName,
      sortable: field.sortable,
      showInOverviewTable: field.showInOverviewTable,
    }))
    .filter((field: any) => !!field.showInOverviewTable);

    const handleEditClick = (sample) => {
        handleEdit(sample);
        setFormOpen(true);
      };

    const tableColumns: ITableHeaderColumn[] = [
        ...visibleFields,
        {
          type: 'icons',
          icons: [
            {
              icon: <EditIcon />,
              show: RoleManager.isAbleTo('material', 'create'),
              callbackMethod: handleEditClick,
            },
          ],
        },
      ];

      const renderTabs = () => (
      <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
          </Box>
      <Table
      columnNames={tableColumns}
      fetchDataMethod={
        tabValue === 3 ? getSamples : () => ({ data: { docs: [] } })
      }
      title={t('samples', {ns: 'materials'})}
      filtersPassed={filters}
      processData={(data) => {
        return data?.map((row) => {
          return {
            ...row,
            type: t('materialForm_recipe', {
              ns: 'materials',
            }),
          };
        });
      }}
    />
    </>
    );

  const renderMemoTabs = useMemo(() => renderTabs(), [tabValue, filters, t]);

  return (
    <div className="page-process">
      {renderMemoTabs}
      <SampleForm
        open={formOpen}
        setOpen={setFormOpen}
        setFilters={setFilters}
        t={t}
        tabValue={tabValue}
        handleEdit={handleEdit}
      />
    </div>
  );
}