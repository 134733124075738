import API from 'services/api';

export const createSample = async (data: any) => {
  return await API.post('/sample', data);
};

export const getSamples = async (props) => {
  const { limit = 20, page = 0, name, sort, filter } = props;
  return await API.get('/sample', {
    params: { limit, page, name, sort, filter },
  });
};

export const editSample = async (id: number, data: any) => {
  return await API.put(`/sample/${id}`, data);
}