import {
  CONVERSATION_CLEAR,
  CONVERSATION_SINGLE_ERROR,
  CONVERSATION_SINGLE_START,
  CONVERSATION_SINGLE_SUCCESS,
  GET_CONVERSATIONS_ERROR,
  GET_CONVERSATIONS_START,
  GET_CONVERSATIONS_SUCCESS,
  MESSAGE_SINGLE_LOADING,
  MESSAGE_SINGLE_SUCCESS,
} from './ChatDialogTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  conversations: [],
  conversation: {
    conversationID: '',
    messages: [],
    messageLoading: false,
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_CONVERSATIONS_START:
      return { ...state, loading: true, error: null };

    case GET_CONVERSATIONS_SUCCESS:
      return { ...state, conversations: action.payload, loading: false };

    case GET_CONVERSATIONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CONVERSATION_SINGLE_START:
      return { ...state, loading: true, error: null };

    case CONVERSATION_SINGLE_SUCCESS:
      return { ...state, conversation: action.payload, loading: false };

    case CONVERSATION_CLEAR:
      return {
        ...state,
        conversation: INITIAL_STATE.conversation,
        loading: false,
      };

    case CONVERSATION_SINGLE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case MESSAGE_SINGLE_LOADING:
      return {
        ...state,
        conversation: { ...state.conversation, messageLoading: true },
        error: false,
      };

    case MESSAGE_SINGLE_SUCCESS:
      return {
        ...state,
        conversation: { ...action.payload, messageLoading: false },
        error: action.payload,
      };

    default:
      return state;
  }
};
