import API from 'services/api';

export const createProcess = async (data: any) => {
  return await API.post('/process', data);
};

export const getProcesses = async (props) => {
  const { limit = 20, page = 0, name, sort, filter } = props;
  return await API.get('/process', {
    params: { limit, page, name, sort, filter },
  });
};

export const editProcess = async (id: number, data: any) => {
  return await API.put(`/process/${id}`, data);
}