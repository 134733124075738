import {
  ADD_PROCESS_TO_EXPERIMENT,
  ADD_SAMPLE_TO_EXPERIMENT,
  EDIT_PROCESS_PARAMETER,
  EXPERIMENT_ADD,
  EXPERIMENT_MARK_FINAL,
  EXPERIMENT_RECIPE_ADD_ROW,
  EXPERIMENT_RECIPE_EDIT_MATERIAL,
  EXPERIMENT_RECIPE_EDIT_PERCENTAGE,
  EXPERIMENT_RECIPE_SAVE_SUCCESS,
  FLAG_PROCESS_USED_IN_SAMPLE,
  PROJECT_EXPERIMENT_FILTER_EXPERIMENT,
  PROJECT_EXPERIMENTS_ERROR,
  PROJECT_EXPERIMENTS_FILTERED_EXPERIMENTS_RESULTS_SUCCESS,
  PROJECT_EXPERIMENTS_RESET,
  PROJECT_EXPERIMENTS_START,
  PROJECT_EXPERIMENTS_SUCCESS,
} from './ProjectRecipeTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    projectId: '',
    experiments: [],
    referenceRecipe: {},
    specifications: [],
    selectedSpecifications: [],
    selectedExperimentResults: [],
    filteredExperiments: [],
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECT_EXPERIMENTS_START:
      return { ...state, loading: true, error: null };

    case PROJECT_EXPERIMENTS_SUCCESS:
      const { experiments, specifications } = action.payload;

      for (let experiment of experiments) {
        experiment = {
          ...experiment,
          recipe: {
            ...experiment.recipe,
            ingredients:
              experiment[experiments.length - 1]?.recipe?.ingredients,
          },
          specifications: specifications,
        };
      }

      return {
        ...state,
        data: {
          ...action.payload,
          experiments: experiments,
        },
        loading: false,
      };

    case EXPERIMENT_RECIPE_ADD_ROW:
      let tempExperiments = state.data.experiments?.map(
        (experiment, experimentIndex) => {
          const generatedId = Date.now();
          return {
            ...experiment,
            recipe: {
              ...experiment.recipe,
              ingredients: [
                ...experiment.recipe?.ingredients,
                {
                  generatedId,
                  materialNumber: '',
                  materialName: '',
                  percentage: 0,
                  position: experiment.recipe?.ingredients.length + 1,
                },
              ],
            },
          };
        },
      );

      return {
        ...state,
        data: {
          ...state.data,
          experiments: tempExperiments,
        },
      };

    case EXPERIMENT_ADD:
      const lastExperiment = [...state.data.experiments]?.pop();

      const tmpProcesses = lastExperiment?.processes?.map((process) => ({
        ...process,
        _id: undefined,
        usedInSemple: false,
        original: false,
        new: true,
      }));

      const firstExperiment = { ...state.data.experiments[0] };
      const newExperiment = {
        ...lastExperiment,
        _id: undefined,
        new: true,
        recipe: {
          ...lastExperiment.recipe,
          ingredients: lastExperiment.recipe.ingredients.map((ingredient) => ({
            ...ingredient,
            generatedId: Date.now(),
          })),
          name: `${firstExperiment?.recipe?.name}-e${
            state.data.experiments.length + 1
          }`,
          new: true,
          _id: undefined,
        },
        samples: [],
        processes: tmpProcesses,
        name: `${firstExperiment?.recipe?.name}-e${
          state.data.experiments.length + 1
        }`,
      };

      return {
        ...state,
        data: {
          ...state.data,
          experiments: [...state.data.experiments, newExperiment],
        },
      };

    case EXPERIMENT_RECIPE_EDIT_PERCENTAGE:
      const { ingredientIndex, experimentIndex, value } = action.payload;

      const experimentsCopy = [...state.data.experiments];

      experimentsCopy[experimentIndex].recipe.ingredients[
        ingredientIndex
      ].percentage = +value;

      return {
        ...state,
        data: {
          ...state.data,
          experiments: experimentsCopy,
        },
      };

    case EXPERIMENT_RECIPE_EDIT_MATERIAL:
      const tmpExperiments = [...state.data.experiments];

      tmpExperiments.map((experiment) => {
        const updatedIngredients = {
          ...experiment.recipe.ingredients[action.payload.ingredientIndex],
          materialName: action.payload.value,
          materialNumber: action.payload.value,
        };
        experiment.recipe.ingredients[action.payload.ingredientIndex] =
          updatedIngredients;

        return { ...experiment };
      });

      return {
        ...state,
        data: {
          ...state.data,
          experiments: tmpExperiments,
        },
      };

    case EXPERIMENT_RECIPE_SAVE_SUCCESS:
      return { ...state, loading: false, error: null };

    case EXPERIMENT_MARK_FINAL:
      return {
        ...state,
        data: {
          ...state.data,
          recipes: state.data.experiments.map((experiment) => {
            if (experiment._id === action.payload.recipe) {
              experiment.final = action.payload.final;
              experiment.recipe.final = action.payload.final;
            }

            return experiment;
          }),
        },
        loading: false,
        error: null,
      };

    case PROJECT_EXPERIMENTS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case PROJECT_EXPERIMENTS_RESET:
      return INITIAL_STATE;

    case PROJECT_EXPERIMENT_FILTER_EXPERIMENT:
      const { experiment, checked } = action.payload;

      let tempFilteredExperiments = [...(state.data.filteredExperiments || [])];

      if (checked && !tempFilteredExperiments.includes(experiment)) {
        tempFilteredExperiments.push(experiment);
      } else {
        tempFilteredExperiments = tempFilteredExperiments.filter(
          (filteredExperiment) => filteredExperiment !== experiment,
        );
      }

      return {
        ...state,
        data: {
          ...state.data,
          filteredExperiments: tempFilteredExperiments,
        },
        loading: false,
      };

    case PROJECT_EXPERIMENTS_FILTERED_EXPERIMENTS_RESULTS_SUCCESS:
      const selectedExperimentsResults: Array<string> = [];
      for (const experiment of action.payload.experiments) {
        if (action.payload.filteredExperiments.includes(experiment._id)) {
          for (const results of experiment.results) {
            for (const resultTable of results.resultTables) {
              selectedExperimentsResults.push(resultTable._id);
            }
          }
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          selectedExperimentsResults: selectedExperimentsResults,
        },
      };

    case ADD_PROCESS_TO_EXPERIMENT:
      const newProcess = {
        ...action.payload.process,
        _id: undefined,
        original: false,
        new: true,
      };

      const experimentWithProcess = state?.data?.experiments?.map(
        (experiment) => {
          return experiment.name === action.payload.name
            ? {
                ...experiment,
                processes: [...(experiment.processes || []), newProcess],
              }
            : experiment;
        },
      );

      return {
        ...state,
        data: {
          ...state.data,
          experiments: experimentWithProcess,
        },
      };

    case EDIT_PROCESS_PARAMETER:
      const {
        updatedParameter,
        parameterIndex,
        updateExperimentIndex,
        processIndex,
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          experiments: state?.data?.experiments?.map((experiment, expIdx) =>
            expIdx === updateExperimentIndex
              ? {
                  ...experiment,
                  processes: experiment?.processes?.map((process, procIdx) =>
                    procIdx === processIndex
                      ? {
                          ...process,
                          parameters: process?.parameters?.map(
                            (parameter, paramIdx) =>
                              paramIdx === parameterIndex
                                ? { ...parameter, ...updatedParameter }
                                : parameter,
                          ),
                        }
                      : process,
                  ),
                }
              : experiment,
          ),
        },
      };

    case ADD_SAMPLE_TO_EXPERIMENT:
      const newSample = {
        ...action.payload.sample,
        _id: undefined,
        new: true,
      };

      const experimentWithSamples = state?.data?.experiments?.map(
        (experiment) => {
          return experiment.name === action.payload.name
            ? {
                ...experiment,
                samples: [...(experiment.samples || []), newSample],
              }
            : experiment;
        },
      );

      return {
        ...state,
        data: {
          ...state.data,
          experiments: experimentWithSamples,
        },
      };

    case FLAG_PROCESS_USED_IN_SAMPLE:
      const experimentWithFlaggedProcess = state?.data?.experiments?.map(
        (experiment) => {
          return experiment.name === action.payload.name
            ? {
                ...experiment,
                processes: experiment?.processes?.map((process) =>
                  process._id === action.payload.process._id
                    ? { ...process, usedInSample: true }
                    : process,
                ),
              }
            : experiment;
        },
      );

      return {
        ...state,
        data: {
          ...state.data,
          experiments: experimentWithFlaggedProcess,
        },
      };

    default:
      return state;
  }
};
