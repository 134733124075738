import {
  PROJECTEDIT_START,
  PROJECTEDIT_SUCCESS,
  PROJECTEDIT_ERROR,
  PROJECTEDIT_SETMEMBERS,
  PROJECTEDIT_SETTESTS,
  PROJECTEDIT_SAVE_SUCCESS,
  PROJECTEDIT_RESET,
  PROJECTEDIT_DUPLICATE_ID,
  PROJECTEDIT_SETPROJECTDOCUMENTS,
  TESTMATRIX_SELECT_MATERIALS,
  TESTMATRIX_SELECT_STANDARDS,
  PROJECTEDIT_PLAN_SUCCESS,
  PROJECT_SPECIFICATION_ADD,
  PROJECT_SPECIFICATION_SELECT_SPECIFICATIONS,
  PROJECTEDIT_SET_RECIPE_TESTS,
  PROJECT_SPECIFICATIONS_ADD_FROM_TEST,
  PROJECT_SPECIFICATIONS_REMOVE_FROM_TEST,
} from './ProjectTypes';
import { sortNameAlphabetically } from '../../utils/transform-helpers';

const INITIAL_STATE = {
  loading: true,
  error: false,
  saveSuccess: false,
  message: '',
  data: {
    status: 'draft',
    duplicate: false,
  },
  devices: [],
  members: [],
  tests: [],
  duplicateProjectId: false,
  hasResults: false,
  isManualInputOnly: false,
  testMatrix: {
    selectedMaterials: [],
    selectedStandards: [],
  },
  experiments: [],
  specificationRowMap: {},
  specifications: [],
  referenceRecipe: null,
  selectedSpecifications: [],
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECTEDIT_START:
      return { ...state, loading: true, error: null };

    case PROJECTEDIT_SAVE_SUCCESS:
      return {
        ...state,
        saveSuccess: true,
        saveMessage: action.payload.message,
        redirectToOverview: action.payload.redirectToOverview,
        loading: false,
      };

    case PROJECTEDIT_PLAN_SUCCESS:
      return {
        ...state,
        saveMessage: action.payload.message,
        redirectToOverview: false,
        loading: false,
      };

    case PROJECTEDIT_SUCCESS:
      const { tests, testMatrix, referenceRecipe, selectedSpecifications, specifications } =
        action.payload;
      const hasResults = tests.filter((test) => test.hasResults);
      const manualInputOnly = tests.filter((test) => test.manualInputOnly);
      const isManualInputOnly = tests.length === manualInputOnly.length;

      if (testMatrix) {
        sortNameAlphabetically(testMatrix?.selectedStandards);
      }

      // action.payload.specifications = referenceRecipe?.specifications || [];

      let specificationRowMap = {};
      action.payload.specifications?.map((spec) => {
        specificationRowMap[spec._id] = spec;
      });
      return {
        ...state,
        data: {
          hasResults: !!hasResults.length,
          isManualInputOnly: isManualInputOnly,
          ...action.payload,
        },
        testMatrix: testMatrix || INITIAL_STATE.testMatrix,
        specifications: specifications,
        referenceRecipe: referenceRecipe,
        specificationRowMap,
        selectedSpecifications: selectedSpecifications,
        loading: false,
      };

    case PROJECTEDIT_ERROR:
      return {
        ...state,
        loading: false,
        redirectToOverview: action.payload.redirectToOverview,
        error: action.payload,
      };

    case PROJECTEDIT_DUPLICATE_ID:
      return {
        ...state,
        duplicateProjectId: action.payload,
      };

    case PROJECTEDIT_SETMEMBERS:
      return { ...state, members: action.payload, loading: false };

    case PROJECTEDIT_SETTESTS:
      return { ...state, tests: action.payload, loading: false };

    case PROJECTEDIT_SET_RECIPE_TESTS:
      return {
        ...state,
        data: {
          ...state.data,
          tests: action.payload,
        },
        loading: false,
      };

    case PROJECTEDIT_SETPROJECTDOCUMENTS:
      return { ...state, documents: action.payload, loading: false };

    case PROJECTEDIT_RESET:
      return {
        ...INITIAL_STATE,
        testMatrix: {
          selectedMaterials: [],
          selectedStandards: [],
        },
        loading: false,
      };

    case TESTMATRIX_SELECT_MATERIALS:
      return {
        ...state,
        testMatrix: {
          ...state.testMatrix,
          selectedMaterials: [...action.payload],
        },
      };

    case TESTMATRIX_SELECT_STANDARDS:
      return {
        ...state,
        testMatrix: {
          ...state.testMatrix,
          selectedStandards: [...action.payload],
        },
      };

    case PROJECT_SPECIFICATION_ADD:
      specificationRowMap = {};
      (action.payload || [])?.map((spec) => {
        specificationRowMap[spec._id] = spec;
      });

      return {
        ...state,
        specifications: action.payload,
        specificationRowMap,
      };

    case PROJECT_SPECIFICATIONS_ADD_FROM_TEST:
      let newSpecificationRowMap = { ...state?.specificationRowMap };
      let newSpecifications: any = [
        ...(state?.specifications || []),
        ...action.payload,
      ];
      (action.payload || [])?.map((spec) => {
        newSpecificationRowMap[spec._id] = spec;
      });

      return {
        ...state,
        specifications: [...newSpecifications],
        specificationRowMap: newSpecificationRowMap,
      };

    case PROJECT_SPECIFICATIONS_REMOVE_FROM_TEST:
      newSpecificationRowMap = { ...state?.specificationRowMap };
      newSpecifications = state?.specifications;
      let newSelectedSpecifications = state?.selectedSpecifications;
      (action.payload || [])?.map((spec) => {
        delete newSpecificationRowMap[spec._id];
        newSpecifications = newSpecifications.filter(
          (specification) => specification.config === spec._id,
        );
        newSelectedSpecifications = newSelectedSpecifications.filter(
          (specification) => specification !== spec._id,
        );
      });

      return {
        ...state,
        specifications: newSpecifications,
        specificationRowMap: newSpecificationRowMap,
        selectedSpecifications: newSelectedSpecifications,
      };

    case PROJECT_SPECIFICATION_SELECT_SPECIFICATIONS:
      return {
        ...state,
        selectedSpecifications: action.payload,
      };

    default:
      return state;
  }
};
