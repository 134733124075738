import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  Divider,
  Drawer,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { BoxWrap } from 'components/BoxWrap/BoxWrap';
import { DynamicForm } from 'components/Forms/DynamicForm/DynamicForm';
import { MultiTabForm } from '../Forms/MultiTabForm/MultiTabForm';

import './DrawerTabs.scss';

export const DrawerTabs = (props) => {
  const {
    tabs,
    submitFormActions,
    open,
    setOpen,
    formData,
    passedFormInstance,
    clearFormData,
    title,
    t,
    multiTabbedForm = false,
    editMode = false,
    information = false,
    canSave = true,
    addNewItem = false,
  } = props;

  const localFormInstance = useForm({
    shouldUnregister: !multiTabbedForm,
    reValidateMode: 'onBlur',
  });

  const formInstance = passedFormInstance || localFormInstance;

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    formInstance.reset({ ...formData });
  }, [formData]);

  const handleConfirm = () => {
    setOpen(false);
    clearFormData();
    formInstance.reset({});
  };

  const onSubmit = async (data) => {
    // if sandbox checkbox is false, remove dates
    if(!data?.sandbox) {
      data.dateOfDeactivation = null;
      data.dateOfDeletion = null;
    }
    
    handleConfirm();
    await submitFormActions[0](data);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  // On close reset to first tab
  useEffect(() => {
    if (!open) {
      // Wait for close animation to finish before change
      setTimeout(() => {
        setTabValue(0);
      }, 300);
      formInstance.reset({});
    }
  }, [open]);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {children}
      </div>
    );
  };

  const checkTabFormError = (fields) => {
    let hasError: any = [];
    if (fields.length) {
      fields?.forEach((field) => {
        if (formInstance.formState.errors[field.fieldName]) {
          hasError.push(field);
        }
      });
    }

    if (Object.keys(fields).length) {
      const keys: any = Object.keys(fields);
      keys?.forEach((field) => {
        if (
          formInstance.formState.errors[field] ||
          (formInstance.formState.errors['contact'] &&
            formInstance.formState.errors['contact'][field])
        ) {
          hasError.push(field);
        }
      });
    }

    return hasError.length;
  };

  const checkTabDisabled = (tab) => {
    const disabledOn = tab?.disabledOn;
    if (disabledOn === 'location') {
      const formLocation = props.passedFormInstance?.watch('location');
      return !formLocation;
    }
    if (typeof disabledOn !== 'undefined' && typeof disabledOn === 'string') {
      if (typeof formInstance.watch(disabledOn) === 'undefined') {
        return formData?.data[disabledOn] !== 'none';
      } else {
        return formInstance.watch(disabledOn) !== 'none';
      }
    } else {
      return disabledOn;
    }
  };

  const renderTabs = (drawerTabs) => {
    return Object.keys(drawerTabs).map((key) => {
      return (
        <Tab
          label={t(drawerTabs[key].name)}
          className="form-tab"
          icon={
            checkTabFormError(drawerTabs[key].fields) ? (
              <ErrorOutlineOutlinedIcon />
            ) : (
              ''
            )
          }
          key={key}
          disabled={checkTabDisabled(drawerTabs[key])}
        />
      );
    });
  };

  const renderTabContent = (tabContent) => {
    return Object.keys(tabContent).map((key, index) => {
      return (
        <TabPanel value={tabValue} index={index} key={`${key}-tab-content`}>
          {multiTabbedForm ? (
            <MultiTabForm
              form={formInstance}
              formData={formData[tabContent[key].stateFieldName]}
              submitForm={submitFormActions[0]}
              fields={tabContent[key].fields}
              confirm={handleConfirm}
              editMode={editMode}
              t={t}
              canSave={canSave}
            />
          ) : (
            <DynamicForm
              form={formInstance}
              formData={formData[tabContent[key].stateFieldName]}
              submitForm={submitFormActions[tabValue]}
              fields={tabContent[key].fields}
              confirm={handleConfirm}
              information={information}
              editMode={editMode}
              t={t}
            />
          )}
        </TabPanel>
      );
    });
  };

  const renderMultiTabForm = () => {
    let template =
      Object.keys(tabs).length > 1 ? (
        <div>
          <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
            {renderTabs(tabs)}
          </Tabs>
          {renderTabContent(tabs)}
        </div>
      ) : (
        <MultiTabForm
          form={formInstance}
          formData={formData[tabs['tab1'].stateFieldName]}
          submitForm={submitFormActions[0]}
          fields={tabs['tab1'].fields}
          confirm={handleConfirm}
          editMode={editMode}
          t={t}
          canSave={canSave}
        />
      );

    return (
      <form onSubmit={formInstance.handleSubmit(onSubmit)}>{template}</form>
    );
  };

  const renderSingleTabForm = () => {
    if (Object.keys(tabs).length > 1) {
      return (
        <div>
          <Tabs value={tabValue} onChange={handleChange}>
            {renderTabs(tabs)}
          </Tabs>
          {renderTabContent(tabs)}
        </div>
      );
    } else {
      return (
        <DynamicForm
          formData={formData[tabs['tab1'].stateFieldName]}
          submitForm={submitFormActions[tabValue]}
          fields={tabs['tab1'].fields}
          confirm={handleConfirm}
          form={formInstance}
          information={information}
          editMode={editMode}
          t={t}
          addNewItem={addNewItem}
        />
      );
    }
  };

  const render = () => {
    return multiTabbedForm ? renderMultiTabForm() : renderSingleTabForm();
  };

  return (
    <React.Fragment key="right">
      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
          clearFormData();
          formInstance.reset({});
        }}
      >
        <BoxWrap>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" className="tab-name">
                {title}
              </Typography>
              <Divider />
            </Grid>
          </Grid>
          {render()}
        </BoxWrap>
      </Drawer>
    </React.Fragment>
  );
};
