import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { ContactSupport } from '@material-ui/icons';

export interface Message {
  Content: string;
  Role: MESSAGE_ROLE;
  Timestamp: string;
  ToolRequest?: object;
  ToolResponse?: object;
}

export enum MESSAGE_ROLE {
  USER = 'User',
  SYSTEM = 'System',
  ASSISTANT = 'Assistant',
  LAB_ASSISTANT = 'LabV Assistant',
  LAB_ASSISTANT_TOOL = 'LabV Assistant (tool)',
}

export const Messages = ({ messages, fullScreen = false }) => {
  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      justify="flex-start"
      style={{ maxHeight: fullScreen ? '80vh' : '60vh', overflowY: 'scroll' }}
    >
      {messages.length &&
        messages?.map((message: Message) => <Message message={message} />)}
    </Grid>
  );
};

const Message = ({ message }) => {
  const [showTool, setShowTool] = useState(false);
  return (
    <Grid item xs={12} alignItems="center" justify="center">
      <Typography
        variant="h4"
        color={message.Role !== MESSAGE_ROLE.USER ? 'primary' : 'secondary'}
      >
        {message.Role}:
        {message.collapsible ? (
          <ContactSupport onClick={() => setShowTool(!showTool)} />
        ) : null}
      </Typography>

      {message.collapsible && showTool ? (
        <Accordion>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ backgroundColor: '#555' }}
          >
            <Grid container item xs={12} justify="center">
              <Typography variant="body2" style={{ color: 'white' }}>
                {message.collapsible.type}
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#f1f1f1' }}>
            <div>
              <pre>
                <div
                  dangerouslySetInnerHTML={{ __html: message.collapsible.html }}
                ></div>
              </pre>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}

      <Typography
        variant="body2"
        color={message.Role !== MESSAGE_ROLE.USER ? 'primary' : 'secondary'}
      >
        {message.html ? (
          <div dangerouslySetInnerHTML={{ __html: message.html }}></div>
        ) : (
          'Click on the question mark for debug info'
        )}
      </Typography>
    </Grid>
  );
};
