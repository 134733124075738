import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessView } from './ProcessView';
import { selectProcessToEdit } from '../ProcessForm/ProcessFormActions';
import { useDispatch } from 'react-redux';
import { fetchProcesses } from './ProcessActions';

export default (props) => {
    const { t } = useTranslation(['materials', 'common']);

    const {
        filters,
        setFilters,
        isInModal,
        onSelect,
        selectedData,
        handleView,
        tabValue,
    } = props;

    const dispatch = useDispatch();

    const handleProcessEdit = async(item) => {
        await dispatch(selectProcessToEdit(item));
      };

    return (
        <ProcessView
            filters={filters}
            setFilters={setFilters}
            isInModal={isInModal}
            onSelect={onSelect}
            selectedData={selectedData}
            handleView={handleView}
            handleEdit={handleProcessEdit}
            tabValue={tabValue}
            t={t}
        />
    );
};