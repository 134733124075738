import {
    GET_PROCESS_FORM,
    GET_PROCESS_ERROR,
    PROCESS_CLEAR,
    PROCESS_SINGLE_SUCCESS,
    PROCESS_UPDATE_SUCCESS,
    PROCESS_CREATE_SUCCESS,
    PROCESS_AUDIT,
    PROCESS_FORM_ADD_PARAMETERS,
  } from './ProcessFormTypes';
  
  const INITIAL_STATE = {
    error: null,
    loading: false,
    locales: [],
    audit: [],
    data: {
      processId: '',
      name: '',
      description: '',
      status: '',
      parameters: [],
    },
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case PROCESS_AUDIT:
        return { ...state, audit: action.payload };
  
      case GET_PROCESS_FORM:
        return { ...state, loading: true };
  
      case PROCESS_SINGLE_SUCCESS:
        return { ...state, loading: false, data: action.payload };
  
      case PROCESS_UPDATE_SUCCESS:
        return { ...state, loading: false };
  
      case PROCESS_CREATE_SUCCESS:
        return { ...state, loading: false };
  
      case GET_PROCESS_ERROR:
        return { ...state, loading: false, data: null, error: action.payload };
  
      case PROCESS_CLEAR:
        return { ...state, loading: false, data: null, error: null };
  
      case PROCESS_FORM_ADD_PARAMETERS:
        return { ...state, data: { ...state.data, parameters: [...state.data?.parameters || [], {name: '', unit: '', vlaue: ''}] } };

      case PROCESS_SINGLE_SUCCESS:
        return { ...state, loading: false, data: action.payload };

      default:
        return state;
    }
  };
  