import { GET_SAMPLE_ERROR, GET_SAMPLE_FORM, SAMPLE_AUDIT, SAMPLE_CLEAR, SAMPLE_CREATE_SUCCESS, SAMPLE_SINGLE_SUCCESS, SAMPLE_UPDATE_SUCCESS } from "./SampleFormTypes";

  
  const INITIAL_STATE = {
    error: null,
    loading: false,
    locales: [],
    audit: [],
    data: {
      sampleId: '',
      name: '',
      description: '',
      status: '',
      processes: [],
      fromRecipe: '',
      fromSample: '',
      quantity: '',
      unitOfMeasurement: '',
    },
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SAMPLE_AUDIT:
        return { ...state, audit: action.payload };
  
      case GET_SAMPLE_FORM:
        return { ...state, loading: true };
  
      case SAMPLE_SINGLE_SUCCESS:
        return { ...state, loading: false, data: action.payload };

      case SAMPLE_CREATE_SUCCESS:
        return { ...state, loading: false };
  
      case SAMPLE_UPDATE_SUCCESS:
        return { ...state, loading: false };
  
      case GET_SAMPLE_ERROR:
        return { ...state, loading: false, data: null, error: action.payload };
  
      case SAMPLE_CLEAR:
        return { ...state, loading: false, data: null, error: null };
  
      default:
        return state;
    }
  };
  