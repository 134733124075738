import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerTabs } from '../../components/DrawerTabs/DrawerTabs';
import { addParameters, clearProcess, newProcess, updateProcess } from './ProcessFormActions';

export default (props) => {
    const { t, tabValue, setFilters} = props;
    const dispatch = useDispatch();
    const processData = useSelector((state: any) => state.processForm);
    const processes = useSelector((state: any) => state.process);
    const config = JSON.parse(localStorage.getItem('config') || '{}');
    const formData = config?.forms?.processForm;


    formData.tab1.fields.filter((field) => {
      return field.showInProcessForm === true;
    });
    
    const submitFormActions = [
        async (processFormData) => {
          if (processData.data && processData.data._id) {
            await dispatch(
              updateProcess(processData.data._id, {
                ...processFormData,
              }),
            );
          } else {
            await dispatch(
              newProcess(
                {
                  ...processFormData,
                  processId: `P-${processes.data.totalDocs + 1}`,
                  original: true,
                },
                () => {},
              ),
            );
          }
          setFilters((prevState) => {
            return { ...prevState, date: Date.now() };
          });
        },
      ];

      const handleParameterAddition = () => {
         dispatch(addParameters());
      };

      const clearFormData = async () => {
        await dispatch(clearProcess());
      };
      return (
        <DrawerTabs
          formData={processData}
          clearFormData={clearFormData}
          tabs={formData}
          submitFormActions={submitFormActions}
          open={props.open}
          setOpen={props.setOpen}
          title={
            processData.data && processData.data._id
              ? t('editProcess')
              : t('createProcess')
          }
          t={t}
          addNewItem={handleParameterAddition}
        />
      );
}