import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerTabs } from '../../components/DrawerTabs/DrawerTabs';
import { clearSample, updateSample } from './SampleFormActions';

export default (props) => {
    const { t, tabValue, setFilters} = props;
    const dispatch = useDispatch();
    const sampleData = useSelector((state: any) => state.sampleForm);
    const config = JSON.parse(localStorage.getItem('config') || '{}');
    const formData = config?.forms?.sampleForm;
    
    const submitFormActions = [
        async (sampleFormData) => {
          if (sampleData.data && sampleData.data._id) {
            await dispatch(
              updateSample(sampleData.data._id, {
                ...sampleFormData,
              }),
            );
          } 
          setFilters((prevState) => {
            return { ...prevState, date: Date.now() };
          });
        },
      ];

      const clearFormData = async () => {
        await dispatch(clearSample());
      };

      return (
        <DrawerTabs
          formData={sampleData}
          clearFormData={clearFormData}
          tabs={formData}
          submitFormActions={submitFormActions}
          open={props.open}
          setOpen={props.setOpen}
          title={
            sampleData.data && sampleData.data._id
              ? t('editSample')
              : "createSample"
          }
          t={t}
        />
      );
}